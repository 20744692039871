<template>
  <div>
    <iframe :src='frame' width='100%' height='1200px' frameborder='0'></iframe>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { jsPDF } from 'jspdf'
import { useRouter } from '@core/utils'
import prints from '@/api/prints'
import { i18n } from '@/plugins/i18n'
import font from '@/assets/fonts/font.json'
import fontBold from '@/assets/fonts/fontBold.json'
import { sumdate, sumdatetime ,sumdatetimeen} from '@/plugins/filters'
import 'jspdf-autotable'
import drugSticker from '@/api/drugAndCert/drugSticker'

export default {
  setup() {
    const { route } = useRouter()
    const fonts = ref(font)
    const fontsBold = ref(fontBold)
    const frame = ref('')
    const dataDrugLabel = ref({})
    const isShowAddress = ref(true)
    const isShowPhone = ref(true)
    const isShowDate = ref(true)
    const isShowName = ref(true)
    const isShowSuggest = ref(true)
    const isShowExpDate = ref(true)
    const width = ref(0)
    const height = ref(0)
    const setFontColor = ref('#000000')

    i18n.locale = route.value.query.language || 'th'
    const printsDrugLabel = () => {
      prints
        .printsDrugLabel({
          drugsticker_id: route.value.query.id.split(','),
          amount: 1,
          lang: i18n.locale,

          
        })
        .then(res => {
          savePDF(res)
        })
    }

    drugSticker.drugStickerGet().then(res => {
      dataDrugLabel.value = res
      isShowAddress.value = res.sticker_show_address == '1'
      isShowPhone.value = res.sticker_show_tel == '1'
      isShowDate.value = res.sticker_show_date == '1'
      isShowName.value = res.sticker_show_name == '1'
      isShowSuggest.value = res.sticker_show_detail == '1'
      isShowExpDate.value = res.sticker_show_expdate == '1' ? true : false
      width.value = +res.sticker_width
      height.value = +res.sticker_height
      printsDrugLabel()
    })

    const savePDF = data => {
      const doc = new jsPDF({
        orientation: 'landscape',
        format: [width.value, height.value],
        setFontColor: setFontColor.value,
        unit: 'mm',
      })
      doc.setProperties({
        title: 'DRUG LABEL',
      })
      doc.addFileToVFS('TH-Niramit-AS-normal.ttf', fonts.value.data)
      doc.addFileToVFS('TH-Niramit-AS-normal-bold.ttf', fontsBold.value.data)
      doc.addFont('TH-Niramit-AS-normal.ttf', 'TH-Niramit', 'normal')
      doc.addFont('TH-Niramit-AS-normal-bold.ttf', 'TH-Niramit', 'bold')
      doc.setFont('TH-Niramit', 'bold')
      for (let i = 0; i < data.length; i++) {
        let total = 5
        const spacer = 5.5
        doc.setFont('TH-Niramit', 'normal')
        if (isShowDate.value) {
          doc.setFontSize(+dataDrugLabel.value.sticker_font_size + 3)
          doc.text(i18n.locale == 'th' ? sumdatetime(data[i].drugsticker_modify) : sumdatetimeen(data[i].drugsticker_modify), width.value - 5, total + 2, null, null, 'right')
          total += spacer
        }
        doc.setFont('TH-Niramit', 'bold')
        if (isShowName.value) {
          doc.setFontSize(+dataDrugLabel.value.sticker_font_size + 2)
          doc.text(data[i].shop_name, 3, total)
          total += spacer
        }
        doc.setFontSize(+dataDrugLabel.value.sticker_font_size)
        doc.setFont('TH-Niramit', 'bold')
        if (isShowAddress.value) {
          const textAddress = `${data[i].shop_address} ${data[i].shop_district} ${data[i].shop_amphoe} ${data[i].shop_province} ${data[i].shop_zipcode}`
          const address = doc.splitTextToSize(textAddress, width.value - 6)
          address.forEach(text => {
            doc.text(text, 3, total)
            total += spacer
          })
        }
        if (isShowPhone.value) {
          doc.text(`${i18n.t('tel')} ${data[i].shop_tel}`, 3, total)
          total += spacer
        }

        // total += 1
        doc.text(`${data[i].customer_fullname.replaceAll('\r', '').replaceAll('\n', '')}`, 3, total)
        total += spacer

        const drugData = `${data[i].drug_name.replaceAll('\r', '').replaceAll('\n', '')} (${data[i].drug_id
          .replaceAll('\r', '')
          .replaceAll('\n', '')}) #${data[i].drugsticker_amount.replaceAll('\r', '').replaceAll('\n', '')} ${data[
          i
          ].drugsticker_unit
          .replaceAll('\r', '')
          .replaceAll('\n', '')}`
        const drug = doc.splitTextToSize(drugData, width.value - 6)
        drug.forEach(text => {
          doc.text(text, 3, total)
          total += spacer
        })
        if (isShowExpDate.value) {
          doc.text(`(EXP : ${sumdate(data[i].drugsticker_expdate)})`, 3, total)
          total += spacer
        }

        const how_to_use = `${i18n.t('direction')} : ${data[i].drugsticker_direction}`
        const how_to_useData = doc.splitTextToSize(how_to_use, width.value - 6)
        how_to_useData.forEach(text => {
          doc.text(text, 3, total)
          total += spacer
        })
        if (isShowSuggest.value) {
          const suggestion = `${i18n.t('suggestions')} : ${data[i].drug_detail}`
          const suggestionData = doc.splitTextToSize(suggestion, width.value - 6)
          suggestionData.forEach(text => {
            doc.text(text, 3, total)
            total += spacer
          })
        }
        doc.addPage(1)
      }
      doc.deletePage(data.length + 1)
      frame.value = doc.output('datauristring')
    }

    return { savePDF, frame }
  },
}
</script>
